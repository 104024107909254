import queryString from 'query-string';
import { AccountBilling, AccountConfig, AccountMonthlyReport, ChainRun, PaginatedCursorQuery, PaginatedResult } from '../types';
import AuthAPI from './auth';
import { BaseAPI } from './base-api';

export default class AccountsAPI extends BaseAPI {
  private path: string = "accounts";

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(baseUrl: string, auth: AuthAPI) {
    super(baseUrl, auth);
  }

  async getAllAccountChainRun(
    accountId: string,
    query: {
      search?: string;
      ids?: string[];
    } & PaginatedCursorQuery = {}
  ): Promise<PaginatedResult<ChainRun>> {
    try {
      const queryStr = queryString.stringify(query);
      const response = await this.get<PaginatedResult<ChainRun>>(
        `/${this.path}/${accountId}/chain-runs?${queryStr}`
      );
      return response;
    } catch (error) {
      throw this.handleError(error);
    }
  }

  async getAllAccountBillings(
    accountId: string,
    query: {
      ids?: string[];
    } & PaginatedCursorQuery = {}
  ): Promise<PaginatedResult<AccountBilling>> {
    try {
      const queryStr = queryString.stringify(query);
      const response = await this.get<PaginatedResult<AccountBilling>>(
        `/${this.path}/${accountId}/billing?${queryStr}`
      );
      return response;
    } catch (error) {
      throw this.handleError(error);
    }
  }

  async getUsage(
    accountId: string,
    query: {} = {}
  ): Promise<AccountMonthlyReport[]> {
    try {
      const queryStr = queryString.stringify(query);
      const response = await this.get<AccountMonthlyReport[]>(
        `/${this.path}/${accountId}/usage?${queryStr}`
      );
      return response;
    } catch (error) {
      throw this.handleError(error);
    }
  }

  async getAccountConfigByAccountId(accountId: string): Promise<AccountConfig> {
    try {
      const accountConfigByAccountId = await this.get<AccountConfig>(
        `/${this.path}/${accountId}/config`
      );
      return accountConfigByAccountId;
    } catch (error) {
      throw this.handleError(error);
    }
  }
}
