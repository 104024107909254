import { useCallback, useEffect, useState } from 'react';
import Client from '../lib/client';
import { Shipment } from '../shared';

export default function useShipments() {
    const [data, setData] = useState<Shipment[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<Error | null>(null);

    const refetch = useCallback(() => {
        setLoading(true);
        return new Client().api.logistics.getAllShipments({ last: 30 })
            .then((result) => {
                setData(result.data);
                setError(null);
            })
            .catch((err: Error) => {
                console.error('error fetching shipments data for hook', err);
                setError(err);
            })
            .finally(() => setLoading(false));
    }, []);

    useEffect(() => {
        refetch();
    }, [refetch]);

    return { data, refetch, loading, error };
}
