import { useEffect, useState, useCallback } from 'react';
import Client from '../lib/client';
import { AccountMonthlyReport } from '../shared';

export default function useAccountUsage(accountId?: string) {
    const [data, setData] = useState<AccountMonthlyReport[]>();

    const refetch = useCallback(
        (accountId?: string, callback?: (data: AccountMonthlyReport[]) => void) => {
            if (!accountId) {
                return [];
            }
            const client = new Client();
            client.api.accounts.getUsage(accountId)
                .then((result) => {
                    setData(result);
                    callback && callback(result);
                })
                .catch((error: Error) => console.error('error fetching account usage data for hook', error));
        }, []);

    useEffect(() => {
        refetch(accountId);
    }, [accountId, refetch]);

    return { data, refetch };
}
