import { useCallback, useEffect, useState } from 'react';
import Client from '../lib/client';
import { Message } from '../shared';


export default function useThreadMessages(threadId?: string) {
    const [data, setData] = useState<Message[]>([]);

    const refetch = useCallback(
        (threadId?: string, callback?: (data: Message[]) => void) => {
            if (!threadId) {
                return [];
            }
            const client = new Client();
            client.api.threads.getAllThreadMessages(threadId, { last: 50 })
                .then((result) => {
                    // const messages = _.reverse(result.data);
                    const messages = result.data;
                    setData(messages);
                    callback && callback(messages);
                })
                .catch((error: Error) => console.error('error fetching messages data for hook', error));
        },
        [],
    );

    useEffect(() => {
        refetch(threadId);
    }, [threadId, refetch]);


    return { data, refetch };
}
