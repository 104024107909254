import { Page } from '../../../components/layout/page';
import { SideBar } from '../../../components/sidebar';
import { Chat } from '../../../components/chat';
import { CustomRouteProps } from '../../../lib/components/route';
import ChatSidebarBottom from '../../chat/components/sidebar-bottom';
import SidebarMenu from '../../../components/sidebar-menu';
import { Pagination } from '../../../components/pagination';
import { useState, useEffect } from 'react';
import useInboundMessages from '../../../lib/use-inbound-messages';
import useShipments from '../../../lib/use-shipments'; // <-- Make sure this is the correct import
// import Client from '../../../lib/client';
import { InboundMessage, Shipment, Thread } from '../../../shared';
import { getDateRelativeStr } from '../../../lib/utils/date';

const statusMap = {
    PENDING: 'warning',
    IN_TRANSIT: 'info',
    OUT_FOR_DELIVERY: 'info',
    DELIVERED: 'success',
    CANCELLED: 'danger',
};

export default function HomeIndex(props: CustomRouteProps) {
    // const accountConfig = useAccountConfig(props.user?.accountId);
    // const { data: mainThread } = useThread(accountConfig?.threadId);

    const [chatThread, setChatThread] = useState<Thread | null>(null);
    const [chatPrompt, setChatPrompt] = useState<string | null>(null);
    const [chatContextData, setChatContextData] = useState<any | null>(null);
    const [chatAssistantConfig, setChatAssistantConfig] = useState<any | null>(null);

    const [inboxPage, setInboxPage] = useState(1);
    // const [customersPage, setCustomersPage] = useState(1);
    const [shipmentsPage, setShipmentsPage] = useState(1);

    const { data: inboxMessages, refetch: refetchInbox } = useInboundMessages();
    const { data: shipments, refetch: refetchShipments } = useShipments();

    const [toastMessage, setToastMessage] = useState<string | null>(null);

    const handleSummarizeInboundEmail = async (inboundMessage: InboundMessage) => {
        const prompt = 'Summarize and highlight important details from this email';

        setChatPrompt(prompt);
        setChatThread(null); // Start new thread
        setChatContextData({ version: "1.0", entities: [{ id: inboundMessage.id, entity: "inbound_message" }] });
        setChatAssistantConfig({ useTool: 'incoming_email_processor', useInput: JSON.stringify({ inboundMessageId: inboundMessage.id }) });
    };

    const handleUpdateShipmentStatus = async (shipment: Shipment) => {
        const prompt = `Check the status of the ${shipment.carrierType} shipment with tracking number: ${shipment.trackingNumber}. Ensure its up to date iun the system.`;

        setChatPrompt(prompt);
        setChatThread(null); // Start new thread
        setChatContextData({ version: "1.0", entities: [{ id: shipment.id, entity: "shipment" }] });
    };

    const handleChatWithInboundMessage = async (inboundMessage: InboundMessage) => {
        const prompt = 'About this email, ';

        setChatPrompt(prompt);
        setChatThread(null); // Start new thread
        setChatContextData({ version: "1.0", entities: [{ id: inboundMessage.id, entity: "inbound_message" }] });
    };

    const handleChatWithShipment = async (shipment: Shipment) => {
        const prompt = `Check the status of the ${shipment.carrierType} shipment with tracking number: ${shipment.trackingNumber}.`;

        setChatPrompt(prompt);
        setChatThread(null); // Start new thread
        setChatContextData({ version: "1.0", entities: [{ id: shipment.id, entity: "shipment" }] });
    };

    useEffect(() => {
        refetchInbox();
        refetchShipments();
    }, [refetchInbox, refetchShipments]);

    const getStatusLabel = (status: keyof typeof statusMap) => (
        <span className={`badge bg-${statusMap[status]}`}>{status.replace('_', ' ')}</span>
    );

    return (
        <Page {...props}
            leftBar={<SideBar {...props} bdColor='light' bottomContainer={<ChatSidebarBottom user={props.user} />} topContainer={null}><SidebarMenu {...props} /></SideBar>}
            rightBar={{
                content: (
                    <SideBar {...props} bdColor='white'>
                        <Chat
                            reverse={false}
                            thread={chatThread}
                            initialPrompt={chatPrompt}
                            onThreadCreated={(thread) => {
                                setChatThread(thread);
                                setChatPrompt(null); // clear prompt after sending
                            }}
                            onMessageCreated={(message) => {
                                setChatAssistantConfig(null);
                            }}
                            contextData={chatContextData}
                            assistantConfig={chatAssistantConfig}
                        />
                    </SideBar>
                ),
                width: 500,
                collapsible: true,
                toggleBtnIcon: <i className="bi bi-robot me-2" />
            }}
            pageName={props.pageName}
        >
            <div className="container-fluid h-100 p-4">
                <div className="row h-100">
                    <div className="col-lg-6 d-flex flex-column h-100">
                        <div className="card shadow-sm h-100">
                            <div className="card-header">Inbox</div>
                            <div className="card-body flex-grow-1 overflow-auto p-0">
                                <ul className="list-group list-group-flush">
                                    {inboxMessages.map((message, index) => (
                                        <li key={index} className="list-group-item d-flex flex-column">
                                            <div>
                                                <p className="mb-0">{message.sender} <small className="text-muted ms-2">{getDateRelativeStr(message.createdAt)}</small></p>
                                                <p className="mb-1">{message.subject}</p>
                                                <p className="text-muted small mt-1">{message.summary}</p>
                                                <p className="text-muted small mb-0">{[...message.tags, ...message.categories].map((tag) => <span className="badge text-bg-secondary">{tag}</span>)}</p>
                                            </div>
                                            <div className="d-flex justify-content-end">
                                                <button className="btn btn-sm btn-outline-secondary me-2"><i className="bi bi-envelope-slash" /> Blacklist</button>
                                                <button onClick={() => handleChatWithInboundMessage(message)} className="btn btn-sm btn-outline-secondary me-2"><i className="bi bi-chat" /> Chat</button>
                                                {
                                                    message.summary ? null : <button onClick={() => handleSummarizeInboundEmail(message)} className="btn btn-sm btn-outline-secondary"><i className="bi bi-lightbulb" /> Summarize</button>
                                                }
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="card-footer text-center">
                                <Pagination
                                    hasNextPage={inboxMessages.length >= 50}
                                    hasPreviousPage={inboxPage > 1}
                                    onNext={() => {
                                        setInboxPage(inboxPage + 1);
                                        refetchInbox();
                                    }}
                                    onPrevious={() => {
                                        setInboxPage(Math.max(1, inboxPage - 1));
                                        refetchInbox();
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 d-flex flex-column h-100">

                        <div className="card shadow-sm flex-grow-1">
                            <div className="card-header">Shipments</div>
                            <div className="card-body flex-grow-1 overflow-auto p-2">
                                <ul className="list-group list-group-flush">
                                    {shipments.map((shipment, index) => (
                                        <li key={index} className="list-group-item d-flex flex-column">
                                            <div>
                                                <p className="mb-0">{shipment.carrierType.toUpperCase()} - {shipment.trackingNumber}</p>
                                                <p className="mb-0">{shipment.description}</p>
                                                <p className="text-muted small mb-0">
                                                    <small className="text-muted">Last Updated: {new Date(shipment.lastUpdateAt).toLocaleDateString()}</small>
                                                </p>
                                                <p className="text-muted small mb-0">
                                                    {shipment.status !== 'DELIVERED' && shipment.estimatedDelivery && (
                                                        <small className="text-muted">ETA: {new Date(shipment.estimatedDelivery).toLocaleDateString()}</small>
                                                    )}
                                                </p>
                                            </div>
                                            <div className="d-flex justify-content-end">
                                                {getStatusLabel(shipment.status as keyof typeof statusMap)}
                                                {
                                                    shipment.status !== 'DELIVERED' ? (
                                                        <button
                                                            className="btn btn-sm btn-outline-secondary ms-2"
                                                            onClick={() => handleUpdateShipmentStatus(shipment)}
                                                        >
                                                            <i className="bi bi-arrow-repeat" /> Update
                                                        </button>
                                                    ) : null
                                                }
                                                <button onClick={() => handleChatWithShipment(shipment)} className="btn btn-sm btn-outline-secondary ms-2"><i className="bi bi-chat" /> Chat</button>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="card-footer text-center">
                                <Pagination
                                    hasNextPage={shipments.length >= 30}
                                    hasPreviousPage={shipmentsPage > 1}
                                    onNext={() => {
                                        setShipmentsPage(shipmentsPage + 1);
                                        refetchShipments();
                                    }}
                                    onPrevious={() => {
                                        setShipmentsPage(Math.max(1, shipmentsPage - 1));
                                        refetchShipments();
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="toast-container position-fixed bottom-0 end-0 p-3"
                style={{ zIndex: 1055 }}
            >
                {toastMessage && (
                    <div className="toast show align-items-center text-white bg-primary border-0" role="alert">
                        <div className="d-flex">
                            <div className="toast-body">
                                {toastMessage}
                            </div>
                            <button
                                type="button"
                                className="btn-close btn-close-white me-2 m-auto"
                                aria-label="Close"
                                onClick={() => setToastMessage(null)}
                            ></button>
                        </div>
                    </div>
                )}
            </div>
        </Page>
    );
}
