import queryString from 'query-string';
import { PaginatedCursorQuery, PaginatedResult, Shipment } from '../types';
import AuthAPI from './auth';
import { BaseAPI } from './base-api';

export default class LogisticsAPI extends BaseAPI {
    private path: string = 'logistics';

    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor(
        baseUrl: string,
        auth: AuthAPI,
    ) {
        super(baseUrl, auth);
    }

    async getAllShipments(
        query: {
            ids?: string[],
            trackingNumbers?: string[],
            search?: string,
            statuses?: string[],
            carrierTypes?: string[],
        } & PaginatedCursorQuery = {}
    ): Promise<PaginatedResult<Shipment>> {
        try {
            const queryStr = queryString.stringify(query, { arrayFormat: 'bracket' });
            const response = await this.get<PaginatedResult<Shipment>>(`/${this.path}/shipments?${queryStr}`);
            return response;
        } catch (error) {
            throw this.handleError(error);
        }
    }
}
