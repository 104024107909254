import { useCallback, useEffect } from 'react';
import WSClient, { WSMessage } from './client/ws';

export function useWSMessage(
    topic: string,
    callback: (data: WSMessage) => void,
    deps: any[] = [],
) {
    const _callback = useCallback(callback, [callback]);
    useEffect(() => {
        try {
            const handlerId = WSClient.on(topic, (message) => {
                _callback(message);
            });
            return () => WSClient.off(handlerId);            
        } catch (error) {
            console.error('error subscribing to WS topic', topic, error);
        }
    }, [_callback, topic]);
}