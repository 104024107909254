import queryString from 'query-string';
import { Message, PaginatedCursorQuery, PaginatedResult, Thread } from '../types';
import AuthAPI from './auth';
import { BaseAPI } from './base-api';

export default class ThreadsAPI extends BaseAPI {
    private path: string = 'threads';

    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor(
        baseUrl: string,
        auth: AuthAPI,
    ) {
        super(baseUrl, auth);
    }

    async getAll(
        query: {
            search?: string,
            ids?: string[],
        } & PaginatedCursorQuery = {}
    ): Promise<PaginatedResult<Thread>> {
        try {
            const queryStr = queryString.stringify(query);
            const response = await this.get<PaginatedResult<Thread>>(`/${this.path}?${queryStr}`);
            return response;
        } catch (error) {
            throw this.handleError(error);
        }
    }

    async create(description?: string): Promise<Thread> {
        try {
            const response = await this.post<Thread>(`/${this.path}`, { description });
            return response;
        } catch (error) {
            throw this.handleError(error);
        }
    }

    async getAllThreadMessages(
        threadId: string,
        query: {
            search?: string,
            ids?: string[],
        } & PaginatedCursorQuery = {}
    ): Promise<PaginatedResult<Message>> {
        try {
            const queryStr = queryString.stringify(query);
            const response = await this.get<PaginatedResult<Message>>(`/${this.path}/${threadId}/messages?${queryStr}`);
            return response;
        } catch (error) {
            throw this.handleError(error);
        }
    }

    async createThreadMessage(threadId: string, body: string): Promise<Message> {
        try {
            const response = await this.post<Message>(`/${this.path}/${threadId}/messages`, { body });
            return response;
        } catch (error) {
            throw this.handleError(error);
        }
    }
}
