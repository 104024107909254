import React from 'react';
// import Client from '../lib/client';
import { User } from '../shared';

// const signOut = async () => {
//     const client = new Client();
//     await client.api.auth.signOut();
// }

type SideBarProps = {
    user?: User;
    topContainer?: React.ReactNode,
    bottomContainer?: React.ReactNode,
    children?: React.ReactNode,
    bdColor?: 'light' | 'dark' | 'white',
    width?: number,
};
export function SideBar(props: SideBarProps) {
    return (
        <div className={`side-bar d-flex flex-column bg-${props.bdColor || 'light'} text-${props.bdColor === 'dark' ? 'light' : 'dark'}`}>
            {
                props.topContainer ? (
                    <div className="px-2">
                        {props.topContainer}
                    </div>
                ) : null
            }
            <div className="d-flex flex-fill overflow-auto">
                {props.children}
            </div>
            {
                props.bottomContainer ? (
                    <div className="p-3">
                        {props.bottomContainer}
                    </div>
                ) : null
            }
        </div>
    );
}
