import React from 'react';

type PaginationProps = {
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    onNext: () => void;
    onPrevious: () => void;
};

export const Pagination: React.FC<PaginationProps> = ({ hasNextPage, hasPreviousPage, onNext, onPrevious }) => {
    return (
        <div className="d-flex justify-content-between align-items-center">
            <button
                className="btn btn-outline-primary btn-sm"
                onClick={onPrevious}
                disabled={!hasPreviousPage}
            >
                <i className="bi bi-arrow-left me-1"></i> Previous
            </button>
            <button
                className="btn btn-outline-primary btn-sm"
                onClick={onNext}
                disabled={!hasNextPage}
            >
                Next <i className="bi bi-arrow-right ms-1"></i>
            </button>
        </div>
    );
};
