import { useEffect, useState, useCallback } from 'react';
import Client from '../lib/client';
import { AdminAccountMonthlyReport } from '../shared';

export default function useAdminAccountUsage() {
    const [data, setData] = useState<AdminAccountMonthlyReport[]>();

    const refetch = useCallback(
        (callback?: (data: AdminAccountMonthlyReport[]) => void) => {
            const client = new Client();
            client.api.admin.accounts.getUsage()
                .then((result) => {
                    setData(result);
                    callback && callback(result);
                })
                .catch((error: Error) => console.error('error fetching account usage data for hook', error));
        },
        []
    );

    useEffect(() => {
        refetch();
    }, [refetch]);

    return { data, refetch };
}
