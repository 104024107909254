import { useEffect, useState, useCallback } from 'react';
import Client from './client';
import { AccountBilling, PaginatedResult } from '../shared';

export default function useAccountBillings(accountId?: string) {
    const [data, setData] = useState<PaginatedResult<AccountBilling>>();

    const refetch = useCallback(
        (accountId?: string, callback?: (data: PaginatedResult<AccountBilling>) => void) => {
            if (!accountId) {
                return [];
            }
            const client = new Client();
            client.api.accounts.getAllAccountBillings(accountId, { last: 50 })
                .then((result) => {
                    setData(result);
                    callback && callback(result);
                })
                .catch((error: Error) =>
                    console.error('error fetching account billings runs data for hook', error)
                );
        },
        [],
    );

    useEffect(() => {
        refetch(accountId);
    }, [accountId, refetch]);

    return { data, refetch };
}
