import { useCallback, useEffect, useState } from 'react';
import Client from '../lib/client';
import { InboundMessage } from '../shared';


export default function useInboundMessages() {
    const [data, setData] = useState<InboundMessage[]>([]);

    const refetch = useCallback(() => {
        new Client().api.inboundMessages.getAll({ last: 50 })
            .then((result) => {
                setData(result.data);
            })
            .catch((error: Error) =>
                console.error('error fetching inbound messages data for hook', error)
            );
    }, []);

    useEffect(() => {
        refetch();
    }, [refetch]);


    return { data, refetch };
}
