import { Link } from "react-router-dom";

const MENU: SidebarMenuItemProps[] = [
    { type: 'link', icon: 'bi-bar-chart', to: '/', pageName: 'home', label: 'Dashboard' },
    // { type: 'link', icon: 'bi-chat', to: '/chat', pageName: 'chat', label: 'Chat' },
    // { type: 'div' },
    { type: 'title', label: 'Account Management' },
    { type: 'link', icon: 'bi-sliders', to: '/account/settings', pageName: 'account-settings', label: 'Settings' },
    { type: 'link', icon: 'bi-speedometer2', to: '/account/usage', pageName: 'account-usage', label: 'Usage' },
    { type: 'link', icon: 'bi-people', to: '/account/members', pageName: 'account-members', label: 'Members' },
    { type: 'link', icon: 'bi-credit-card', to: '/account/billing', pageName: 'account-billing', label: 'Billing' },
];

type SidebarMenuProps = {
    pageName: string,
};
export default function SidebarMenu(props: SidebarMenuProps) {
    return (
        <div>
            <div className="px-4 py-3">
                <Link className="h5 text-decoration-none text-reset" to="/">
                    ElysiaAI
                </Link>
            </div>
            <ul className="nav nav-pills flex-column mb-auto p-2">
                {MENU.map((item, idx) => <SidebarMenuItem key={idx} pageName={props.pageName} item={item} />)}
            </ul>
        </div>
    );
}

type SidebarMenuItemTitleProps = {
    type: 'title',
    label: string,
    className?: string,
};
type SidebarMenuItemLinkProps = {
    type: 'link',
    icon: string,
    to: string,
    pageName: string,
    label: string,
};
type SidebarMenuItemDivProps = {
    type: 'div'
};
type SidebarMenuItemProps =
    SidebarMenuItemTitleProps
    | SidebarMenuItemLinkProps
    | SidebarMenuItemDivProps;
function SidebarMenuItem(props: { pageName: string, item: SidebarMenuItemProps }) {
    switch (props.item.type) {
        case 'title':
            return (
                <li className="nav-item mt-3">
                    <p className="nav-link text-muted mb-0">
                        {/* <i className="bi bi-chat me-2"></i> */}
                        {props.item.label}
                    </p>
                </li>
            );
        case 'link':
            return (
                <li key={props.item.pageName} className={`${props.pageName === props.item.pageName ? '' : ''}`}>
                    <Link to={props.item.to} className={`nav-link text-reset ${props.pageName === props.item.pageName ? 'fw-bold' : ''}`}>
                        <i className={`bi ${props.item.icon} me-2`}></i>
                        {props.item.label}
                    </Link>
                </li>
            );
        case 'div':
            return <hr />;
        default:
            return null;
    }
}

