import _ from 'lodash';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Page } from '../../../../components/layout/page';
import { SideBar } from '../../../../components/sidebar';
import { CustomRouteProps } from '../../../../lib/components/route';
import SidebarMenu from '../../../../components/sidebar-menu';
import { AdminAccountMonthlyReport } from '../../../../shared';
import useAdminAccountUsage from '../../../../lib/use-admin-account-usage';
// const PRICE_PER_1K_TOKENS = 0.002;

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const optionsDailyUsage = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top' as const,
        },
        title: {
            display: true,
            text: 'Daily usage (USD)',
            position: 'left' as const,
        },
    },
};
const getChartLabelsFromReport = (reportRow: AdminAccountMonthlyReport) => reportRow.day.split('T')[0];
const getChartDataFromReport = (reportRow: AdminAccountMonthlyReport) => reportRow.total.totalTokens;

export default function AccountUsage(props: CustomRouteProps) {
    const {
        data: reportData,
        // refetch: refetchReport,
    } = useAdminAccountUsage();
    const chartData = {
        labels: (reportData || []).map(getChartLabelsFromReport),
        datasets: [
            {
                label: 'Usage',
                data: (reportData || []).map(getChartDataFromReport),
                backgroundColor: 'rgba(2, 184, 117, 0.5)',
            }
        ],
    };
    const totalizeReport = (reportData || []).reduce((agg, data) => ({
        totalTokens: agg.totalTokens + data.total.totalTokens,
        promptTokens: agg.promptTokens + data.total.promptTokens,
        completionTokens: agg.completionTokens + data.total.completionTokens,
    }), { totalTokens: 0, completionTokens: 0, promptTokens: 0 });
    const topAccountsReport = (reportData || []).reduce((agg, data) => {
        const newAgg = { ...agg };
        data.topAccounts.forEach((topAccountData) => {
            if (!newAgg[topAccountData.accountId]) {
                newAgg[topAccountData.accountId] = { accountId: topAccountData.accountId, count: 0, totalTokens: 0, completionTokens: 0, promptTokens: 0 };
            }
            newAgg[topAccountData.accountId].count += topAccountData.count || 0;
            newAgg[topAccountData.accountId].totalTokens += topAccountData.totalTokens || 0;
            newAgg[topAccountData.accountId].completionTokens += topAccountData.completionTokens || 0;
            newAgg[topAccountData.accountId].promptTokens += topAccountData.promptTokens || 0;
        });
        return newAgg;
    }, {} as any);

    return (
        <Page
            {...props}
            leftBar={(
                <SideBar {...props}>
                    <SidebarMenu pageName={props.pageName} />
                </SideBar>
            )}
            pageName={props.pageName}
        >

            <div className="container-fluid">
                <section className="mt-3 mb-2">
                    <div className="row">
                        <div className="col-12 col-md-8">
                            <div className="">
                                <p className="h4">Admin Account usage</p>
                                <p className="">Below you'll find a summary of usage for your organization. Data may be delayed up to 5 minutes.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="mb-5">
                    <div className="row">
                        <div className="col-12 col-sm-6 col-md-3">
                            <div className="card shadow-sm">
                                <div className="card-body">
                                    <p className="mb-0 fs-5 text-muted">Total Tokens</p>
                                    <p className="mb-0 fs-2">{(totalizeReport.totalTokens).toLocaleString()}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="mb-5">
                    <div className="row">
                        <div className="col-12 col-md-8">
                            <div className="d-flex">
                                <div className="flex-grow-1">
                                    <div className="btn-group" role="group" aria-label="Basic example">
                                        <button type="button" className="btn btn-link">
                                            <i className="bi bi-chevron-left me-2"></i>
                                        </button>
                                        <button type="button" className="btn btn-link text-decoration-none text-reset">May</button>
                                        <button type="button" className="btn btn-link">
                                            <i className="bi bi-chevron-right me-2"></i>
                                        </button>
                                    </div>
                                </div>
                                <div className="">
                                    {/* <div className="btn-group" role="group" aria-label="Basic example">
                                        <button type="button" className="btn btn-secondary text-uppercase border-0 shadow-sm rounded-start text-dark">Daily</button>
                                        <button type="button" className="btn btn-secondary text-uppercase border-0 shadow-sm rounded-end bg-light text-muted">Cumulative</button>
                                    </div> */}
                                </div>
                            </div>
                            <div className="">
                                <Bar height={120} options={optionsDailyUsage} data={chartData} />
                            </div>
                        </div>
                    </div>
                </section>
                <section className="mb-5">
                    <div className="row">
                        <div className="col-12 col-md-8">
                            <div className="table-responsive">
                                <table className="table table-hover table-bordered table-sm">
                                    <thead>
                                        <tr className="text-capitalize">
                                            <th>Account Id</th>
                                            <th>Count</th>
                                            <th>Total Tokens</th>
                                            <th>Prompt Tokens</th>
                                            <th>Completion Tokens</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            _.values(topAccountsReport)
                                                .sort((a, b) => b.totalTokens - a.totalTokens)
                                                .map((account) => (
                                                    <tr key={account.accountId}>
                                                        <td>{account.accountId}</td>
                                                        <td>{account.count}</td>
                                                        <td>{account.totalTokens}</td>
                                                        <td>{account.promptTokens}</td>
                                                        <td>{account.completionTokens}</td>
                                                    </tr>
                                                ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
                {/* 
                <section className="mb-5">
                    <div className="row">
                        <div className="col-12 col-md-8">
                            <div className="mb-2">
                                <p className="fw-bold mb-0">Usage this month</p>
                                <div className="d-flex w-100 align-items-center">
                                    <div className="flex-grow-1 progress">
                                        <div className="progress-bar bg-success" role="progressbar" style={{ width: '5%' }} aria-valuenow={6.45} aria-valuemin={0} aria-valuemax={120}></div>
                                    </div>
                                    <div className="ms-3">$6.45 / $120.00</div>
                                </div>
                            </div>
                            <div className="mb-2">
                                <p className="fw-bold mb-0">Credit used</p>
                                <div className="d-flex w-100 align-items-center">
                                    <div className="flex-grow-1 progress">
                                        <div className="progress-bar" role="progressbar" style={{ width: '100%' }} aria-valuenow={100} aria-valuemin={0} aria-valuemax={100}></div>
                                    </div>
                                    <div className="ms-3">$5.00 / $5.00</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
            </div>

        </Page>
    );
}
