import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import SyntaxHighlighter from 'react-syntax-highlighter/dist/esm/default-highlight';
import TextareaAutosize from 'react-textarea-autosize';

import { Message, MessageAssistantConfig, MessageContextData, MessageRole, Thread } from '../../shared';
import useThreads from '../../lib/use-threads';
import { useWSMessage } from '../../lib/use-ws-message';
import Client from '../../lib/client';
import useThreadMessages from '../../lib/use-thread-messages';
import { getDateRelativeStr } from '../../lib/utils/date';

import './chat.css';

// const scrollToTop = () => {
//     const div = window.document.getElementById('thread-messages-container');
//     if (div) {
//         setTimeout(() => {
//             div.scrollTop = div.scrollHeight;
//         }, 1);
//     }
// };

const scrollToBottom = () => {
    const div = window.document.getElementById('thread-messages-container');
    if (div) {
        setTimeout(() => {
            div.scrollTop = 0;
        }, 1);
    }
};

function MessageCard(props: {
    message?: Message,
    onClickAction?: (
        body: string,
        contextData?: MessageContextData,
        assistantConfig?: MessageAssistantConfig,
    ) => Promise<void>,
}
) {
    if (_.isEmpty(props.message?.cardData?.actions)) return null;

    return (
        <div className="d-grid gap-2">
            <p className="text-truncate text-muted mb-0 mt-3"><small>Suggested actions:</small></p>
            {
                (props.message?.cardData?.actions || []).map((action, idx) => (
                    <div
                        key={idx}
                        className="btn btn-light text-start shadow-sm p-2"
                        onClick={() => props.onClickAction && props.onClickAction(action.body, (props.message?.contextData || {}), { ...action })}
                    >
                        <p className="text-truncate mb-0"><i className="bi bi-chat me-3" />{action.body}</p>
                    </div>
                ))
            }
        </div>
    );
}

function ThreadRow(props: { thread: Thread, onClickThread?: (thread: Thread) => void }) {
    return (
        <div
            key={props.thread.id}
            className="bg-light border p-2 mx-0 mb-0"
            onClick={() => props.onClickThread && props.onClickThread(props.thread)}
        >
            <p className="text-truncate mb-0"><i className="bi bi-chat me-3" />{props.thread.description}</p>
            <p className="text-truncate mb-0">
                <small className="text-muted me-2">{props.thread.lastMessageCreatedAt && getDateRelativeStr(props.thread.lastMessageCreatedAt)}</small>
                <small className="text-muted me-2">{props.thread.messageCount} messages</small>
            </p>
        </div>
    );
}
function MessageRow(props: {
    message: Message,
    onCreateMessage: (
        body: string,
        threadId?: string,
        contextData?: MessageContextData,
        assistantConfig?: MessageAssistantConfig,
    ) => Promise<void>,
}) {
    let getRoleClass = (role: MessageRole) => {
        switch (role) {
            case MessageRole.USER:
                return '';
            case MessageRole.ASSISTANT:
                return '';
            case MessageRole.SYSTEM:
                return '';
            default:
                return '';
        }
    };
    let getRoleIcon = (role: MessageRole) => {
        switch (role) {
            case MessageRole.USER:
                return <i className="bi bi-person-circle"></i>;
            case MessageRole.ASSISTANT:
                return <i className="bi bi-robot"></i>;
            case MessageRole.SYSTEM:
                return <i className="bi bi-gear"></i>;
            default:
                return '';
        }
    };

    const onCreateMessage = (
        body: string,
        contextData?: MessageContextData,
        assistantConfig?: MessageAssistantConfig,
    ) => props.onCreateMessage && props.onCreateMessage(body, !assistantConfig.useNewThread ? props.message.threadId : undefined, contextData || {}, assistantConfig || {});

    return (
        <div key={props.message.id} className={`row`}>
            <div className="col">
                <div className={`p-2 rounded mx-2 mb-4 d-flex flex-row`}>
                    <div className="me-2">
                        {getRoleIcon(props.message.role)}
                    </div>
                    <div>
                        <div>
                            <p className="mb-0 fw-bold">
                                {_.capitalize(props.message.role)}
                                <small className="fw-normal text-muted ms-2">{getDateRelativeStr(props.message.createdAt)}</small>
                            </p>
                        </div>
                        <div>
                            <ReactMarkdown
                                className={`message-md-body-container ${getRoleClass(props.message.role)}`}
                                children={props.message.body}
                                remarkPlugins={[remarkGfm]}
                                components={{
                                    code({ node, inline, className, children, ...props }) {
                                        const match = /language-(\w+)/.exec(className || '')
                                        return !inline && match ? (
                                            <SyntaxHighlighter
                                                {...props}
                                                children={String(children).replace(/\n$/, '')}
                                                style={atomDark}
                                                language={match[1]}
                                                PreTag="div"
                                            />
                                        ) : (
                                            <code {...props} className={className}>
                                                {children}
                                            </code>
                                        )
                                    }
                                }}
                            />
                            <div className="message-context-container">
                                <p className="mb-0">
                                    {/* {JSON.stringify(props.message?.contextData || {})} */}
                                </p>
                            </div>
                            <div className="message-card-container">
                                <p className="mb-0">
                                    <MessageCard message={props.message} onClickAction={onCreateMessage} />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function ChatThreadList(props: {
    onCreateThread: (thread: Partial<Thread>) => Promise<void>,
    threads: Thread[],
    selectedThread: Thread | null,
    onClickThread?: (thread: Thread) => void,
    onClose?: () => void | Promise<void>,
}) {
    let threads = props.threads;

    if (props.selectedThread && !threads.find((thread) => props.selectedThread && thread.id === props.selectedThread.id)) {
        threads = [props.selectedThread, ...threads];
    }

    return (
        <div className="d-flex flex-column flex-fill border-start w-100 overflow-hidden">
            <div className="d-flex flex-column p-2 border-light border-bottom shadow">
                <p className="h6 text-truncate text-capitalize mt-2"><i className="bi bi-robot me-2"></i>Ask ElysiaAI</p>
                <div className="d-flex justify-content-end">
                    {props.onClose && <button className="btn btn-sm btn-secondary ms-2" onClick={() => props.onClose && props.onClose()}>Close</button>}
                    <button className="btn btn-sm btn-primary ms-2" onClick={() => props.onCreateThread({ description: 'New Thread' })}>New Thread</button>
                </div>
            </div>

            <div className="d-flex flex-column d-flex flex-fill bg-white w-100 overflow-hidden overflow-y-auto pt-2">
                {threads.map((thread, idx) => (
                    <ThreadRow key={idx} onClickThread={props.onClickThread} thread={thread} />
                ))}
            </div>
        </div>
    );
}
export function ChatThread(props: {
    initialPrompt?: string,
    thread: Thread | null,
    readOnly?: boolean,
    reverse?: boolean,
    onClickBack?: () => void,
    onCreateThread?: (thread: Partial<Thread>) => Promise<void>,
    onCreateMessage?: (message: Message) => Promise<void>,
    onClose?: () => void | Promise<void>,
    contextData?: MessageContextData,
    assistantConfig?: MessageAssistantConfig
}) {
    const [body, setBody] = useState<string>(props.initialPrompt || '');
    const [sentMessage, setSentMessage] = useState<Message | undefined>();
    const { data: messages, refetch } = useThreadMessages(props.thread?.id);

    useWSMessage('thread.updated', (data) => {
        if (props.thread && data.payload.thread && data.payload.thread.id === props.thread.id) {
            setSentMessage(undefined);
            refetch(props.thread?.id);
        }
    }, [props.thread]);

    const onCreateMessage = async (
        body: string,
        threadId?: string,
        contextData?: MessageContextData,
        assistantConfig?: MessageAssistantConfig,
    ) => {
        const client = new Client();
        const isFirstMessage = !threadId;

        console.log('onCreateMessage ==>', {
            assistantConfig,
            assistantConfigProps: props.assistantConfig,
            isFirstMessage,
        });

        const message = await client.api.messages.create(
            body,
            threadId,
            contextData || props.contextData || {}, // fallback to prop
            (assistantConfig || props.assistantConfig || {})
        );

        setBody('');
        setSentMessage(message);
        scrollToBottom();
        props.onCreateMessage && props.onCreateMessage(message);

        if (isFirstMessage && props.onCreateThread) {
            props.onCreateThread({ id: message.threadId });
        }
    };

    const onKeyUp = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.code === 'Enter' && event.shiftKey) {
            // this is weird, but kinda works...
            event.preventDefault();
            setBody(body + '\n');
            return;
        }
        if (event.code === 'Enter') {
            event.preventDefault();
            onCreateMessage(body, props.thread?.id);
        }
    }

    return (
        <div className="d-flex flex-column flex-fill border-start w-100 overflow-hidden">
            <div className="d-flex flex-column p-2 border-light border-bottom shadow-sm">
                <p className="h6 text-truncate text-capitalize mt-2">{props.thread?.description || 'New Thread'}</p>
                <div className="d-flex justify-content-end">
                    {props.onClose && <button className="btn btn-sm btn-secondary btn-small ms-2" onClick={() => props.onClose && props.onClose()}>Close</button>}
                    {props.onClickBack && <button className="btn btn-sm btn-secondary btn-small ms-2" onClick={() => props.onClickBack && props.onClickBack()}>Back</button>}
                    {props.onCreateThread && <button className="btn btn-sm btn-primary btn-small ms-2" onClick={() => props.onCreateThread && props.onCreateThread({ description: 'New Thread'})}>New Thread</button>}
                </div>
            </div>
            <div id="thread-messages-container" className={`d-flex ${props.reverse ? 'flex-column' : 'flex-column-reverse'} d-flex flex-fill bg-white w-100 overflow-hidden overflow-y-auto pt-2`}>
                {(sentMessage ? (props.reverse ? [...messages, sentMessage] : [sentMessage, ...messages]) : messages).map((message, idx) => <MessageRow key={idx} message={message} onCreateMessage={onCreateMessage}></MessageRow>)}
            </div>
            {!props.readOnly && (
                <div className="border-top p-2">
                    {
                        props.contextData ? (
                            <div className="px-2 pb-0 small bg-light border">
                                <p className="mb-0">Context Data:</p>
                                <p className="mb-0">{(props.contextData?.entities || []).map((entity: any) => entity.id).join(',')}</p>
                            </div>
                        ) : null
                    }

                    <div className="border bg-white rounded p-2 align-items-end d-flex flex-column">
                        <TextareaAutosize
                            id="new-message-input"
                            maxRows={20}
                            minRows={1}
                            autoFocus
                            autoComplete="off"
                            value={body}
                            onKeyUp={onKeyUp}
                            onChange={(event) => setBody(event.target.value)}
                            className="new-message-textarea form-control border-0 p-0"
                            placeholder="Send a message..."
                            aria-label="Send a message..."
                            aria-describedby="button-send-message"
                        />
                        <button onClick={() => onCreateMessage(body, props.thread?.id)} disabled={body === ''} className="btn btn-success mt-2" type="button" id="button-send-message">Send</button>
                    </div>
                </div>
            )}
        </div>
    );
}

export function Chat(props: {
    readOnly?: boolean;
    reverse?: boolean;
    initialPrompt?: string | null;
    thread?: Thread | null;
    onThreadCreated?: (thread: Thread) => void;
    onMessageCreated?: (message: Message) => void;
    contextData?: MessageContextData;
    assistantConfig?: MessageAssistantConfig;
}) {
    const [initialPrompt, setInitialPrompt] = useState(props.initialPrompt || '');
    const [assistantConfig, setAssistantConfig] = useState(props.assistantConfig || '');
    const [thread, setThread] = useState<Thread | null>(props.thread || null);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { data: threads, refetch } = useThreads();

    const { onThreadCreated } = props;

    const onCreateThread = useCallback(async (threadData: Partial<Thread>) => {
        const client = new Client();
        let thread: Thread;

        if (!threadData.id) {
            thread = await client.api.threads.create(threadData.description || 'New Thread');
        } else {
            const { data: threads } = await client.api.threads.getAll({ ids: [threadData.id] });
            thread = threads[0];
        }

        setThread(thread);
        onThreadCreated?.(thread);
    }, [onThreadCreated]);

    useEffect(() => {
        if (props.initialPrompt) {
            setInitialPrompt(props.initialPrompt || '');
            onCreateThread({});
        }
    }, [props.initialPrompt, onCreateThread]);

    useEffect(() => {
        if (props.assistantConfig) {
            setAssistantConfig(props.assistantConfig || '');
            onCreateThread({});
        }
    }, [props.assistantConfig, onCreateThread]);

    useEffect(() => {
        if (props.thread !== undefined) {
            setThread(props.thread);
        }
    }, [props.thread]);

    const onClickBack = () => {
        setThread(null);
    };

    const onCreateMessage = async (message: Message) => {
        setInitialPrompt('');
        props.onMessageCreated && props.onMessageCreated(message);
    };

    console.log('assistantConfig==>', assistantConfig);
    if (thread) {
        return (
            <ChatThread
                initialPrompt={initialPrompt}
                onClickBack={onClickBack}
                onCreateThread={onCreateThread}
                onCreateMessage={onCreateMessage}
                readOnly={props.readOnly}
                reverse={props.reverse}
                thread={thread}
                contextData={props.contextData}
                assistantConfig={assistantConfig}
            />
        );
    }

    return (
        <ChatThreadList
            selectedThread={thread}
            threads={threads}
            onClickThread={setThread}
            onCreateThread={onCreateThread}
        />
    );
}