import queryString from 'query-string';
import { AdminAccountMonthlyReport } from '../../types';
import AuthAPI from '../auth';
import { BaseAPI } from '../base-api';

export default class AdminAccountsAPI extends BaseAPI {
  private path: string = 'admin/accounts';

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(baseUrl: string, auth: AuthAPI) {
    super(baseUrl, auth);
  }

  async getUsage(
    query: {} = {}
  ): Promise<AdminAccountMonthlyReport[]> {
    try {
      const queryStr = queryString.stringify(query);
      const response = await this.get<AdminAccountMonthlyReport[]>(
        `/${this.path}/usage?${queryStr}`
      );
      return response;
    } catch (error) {
      throw this.handleError(error);
    }
  }

  async generateBilling(): Promise<void> {
    try {
      await this.post<void>(`/${this.path}/billing/generate`, {});
    } catch (error) {
      throw this.handleError(error);
    }
  }
}
