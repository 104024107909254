import queryString from 'query-string';
import { PaginatedCursorQuery, PaginatedResult, User } from '../types';
import AuthAPI from './auth';
import { BaseAPI } from './base-api';

export default class UsersAPI extends BaseAPI {
    private path: string = 'users';

    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor(
        baseUrl: string,
        auth: AuthAPI,
    ) {
        super(baseUrl, auth);
    }

    async getMe(): Promise<User> {
        try {
            const user = await this.get<User>(`/${this.path}/me`);
            return user;
        } catch (error) {
            throw this.handleError(error);
        }
    }

    async register(username: string, email: string, name: string): Promise<User> {
        try {
            const user = await this.post<User>(`/${this.path}`, { username, email, name });
            return user;
        } catch (error) {
            throw this.handleError(error);
        }
    }

    async getAll(
        query: {
            search?: string,
            usernames?: string[],
            ids?: string[],
        } & PaginatedCursorQuery = {}
    ): Promise<PaginatedResult<User>> {
        try {
            const queryStr = queryString.stringify(query);
            const usersResult = await this.get<PaginatedResult<User>>(`/${this.path}?${queryStr}`);
            return usersResult;
        } catch (error) {
            throw this.handleError(error);
        }
    }
}
