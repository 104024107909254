export interface AuthToken {
    userId: string;
    accountId: string;
    authToken: string;
    refreshToken: string;
    expiresAt: number;
}
export interface AuthTokenPayload {
    userId: string;
    accountId: string;
}

export enum UserPermissionRole {
    ADMIN = 'ADMIN',
    USER = 'USER',
}

export enum UserPermissionPermission {
    CREATE_ACTIVITY = 'CREATE_ACTIVITY',
    LIST_ACTIVITY = 'LIST_ACTIVITY',
}

export interface UserPermission {
    id: string;
    userId: string;
    roles: UserPermissionRole[];
    permissions: UserPermissionPermission[];
}

export interface UserProfile {
    id: string;
    userId: string;
    email: string;
    isEmailVerified: boolean;
    lastLoginAt?: number;
}

export enum UserStatus {
    'ACTIVE' = 'ACTIVE',
    'INACTIVE' = 'INACTIVE',
    'UNVERIFIED' = 'UNVERIFIED',
    'DEACTIVATED' = 'DEACTIVATED',
}

export interface User {
    id: string;
    username: string;
    name: string;
    status: UserStatus;
    accountId: string;
}

export interface PaginatedResult<K> {
    data: K[],
    total: number,
    pageInfo: {
        hasNextPage?: boolean;
        hasPrevPage?: boolean;
        startCursor?: string,
        endCursor?: string,
    }
}

export interface PaginatedCursorQuery {
    first?: number,
    afterCursor?: string,
    last?: number,
    beforeCursor?: string,
}

export enum AccountStatus {
    'ACTIVE' = 'ACTIVE',
    'INACTIVE' = 'INACTIVE',
    'UNVERIFIED' = 'UNVERIFIED',
    'DEACTIVATED' = 'DEACTIVATED',
}
export enum AccountPlan {
    'BASIC' = 'BASIC',
    'PRO' = 'PRO',
}
export interface Account {
    id: string;
    name: string;
    plan: AccountPlan;
    status: AccountStatus;
}

export enum MessageStatus {
    'ACTIVE' = 'ACTIVE',
    'INACTIVE' = 'INACTIVE',
    'DEACTIVATED' = 'DEACTIVATED',
}
export enum MessageRole {
    'ASSISTANT' = 'ASSISTANT',
    'USER' = 'USER',
    'SYSTEM' = 'SYSTEM',
}
export type MessageAssistantConfig = any;
// export type MessageContextData = { [key: string]: boolean | number | string | number[] | string[] | MessageContextData };
export type MessageContextData = any;
// export type MessageCardData = { [key: string]: boolean | number | string | number[] | string[] | MessageCardData };
export type MessageCardDataAction = { type: string, body: string, useTool: string, useInput: string };
export type MessageCardData = {
    version: string;
    actions: MessageCardDataAction[];
};
export interface Message {
    id: string;
    body: string;
    accountId: string;
    threadId: string;
    replyMessage?: Message;
    replyMessageId?: string;
    role: MessageRole;
    contextData: MessageContextData;
    assistantConfig: MessageAssistantConfig;
    cardData: MessageCardData;
    status: MessageStatus;
    createdBy: string;
    createdAt: number;
    updatedAt: number;
}

export enum ThreadStatus {
    'ACTIVE' = 'ACTIVE',
    'INACTIVE' = 'INACTIVE',
    'DRAFT' = 'DRAFT',
    'DEACTIVATED' = 'DEACTIVATED',
}
export interface Thread {
    id: string;
    description?: string;
    accountId: string;
    // firstMessage?: Message;
    firstMessageId?: string;
    firstMessageCreatedAt?: number;
    // lastMessage?: Message;
    lastMessageId?: string;
    lastMessageCreatedAt?: number;
    messageCount: number;
    createdBy: string;
    status: ThreadStatus;
}
export enum ChainRunType {
    'agent' = 'agent',
    'chain' = 'chain',
    'tool' = 'tool',
    'llm' = 'llm',
}
export interface ChainRun {
    id: string;
    name: string;
    parentId?: string;
    replyMessageId: string;
    threadId: string;
    accountId: string;
    userId: string;
    type: ChainRunType;
    input: string;
    output?: string;
    startedAt: number;
    endedAt?: number;
    duration?: number;
    completionTokens: number;
    promptTokens: number;
    totalTokens: number;
    totalUsd: number;
    createdAt: number;
}
export enum ChainRunTraceAction {
    'start' = 'start',
    'end' = 'end',
    'error' = 'error',
}
export interface ChainRunTrace {
    id: string,
    runId: string;
    run?: ChainRun;
    replyMessageId: string;
    threadId: string;
    accountId: string;
    userId: string;
    action: ChainRunTraceAction;
    createdAt: number;
}
export interface AccountMonthlyReport {
    id: string;
    day: string;
    promptTokens: number;
    completionTokens: number;
    totalTokens: number;
}
export interface AccountConfig {
    id?: string;
    accountId: string;
    assistantName: string;
    assistantLanguage: string;
    threadId?: string;
    pricePerToken: number;
    maxUsdPerMonth: number;
    creditAvailable: number;
}
export interface AccountBilling {
    id?: string;
    accountId: string;
    periodStart: number;
    periodEnd: number;
    totalTokens: number;
    pricePerToken: number;
    creditUsed: number;
    totalAmount: number;
    paid: boolean;
    createdAt: number;
    updatedAt: number;
}
export enum AssistantLanguage {
    'ENGLISH' = 'English',
    'ESPAÑOL' = 'Español',
}

export enum InboundMessageStatus {
    'PENDING' = 'PENDING',
    'PROCESSED' = 'PROCESSED',
    'INACTIVE' = 'INACTIVE',
    'DEACTIVATED' = 'DEACTIVATED',
}
export enum InboundMessageType {
    'EMAIL' = 'EMAIL',
    'SMS' = 'SMS',
    'SYSTEM' = 'SYSTEM',
}
export interface InboundMessage {
    id: string;
    sender: string;
    recipient: string;
    subject: string;
    type: InboundMessageType;
    body: string;
    rawData: { [key: string]: string };
    accountId: string;
    summary: string;
    shortSummary: string;
    categories: string[];
    tags: string[];
    status: InboundMessageStatus;
    createdBy: string;
    createdAt: number;
    acknowledgeAt: number;
    updatedAt: number;
}
export interface AdminAccountMonthlyReport {
    day: string;
    total: {
        count: number;
        promptTokens: number;
        completionTokens: number;
        totalTokens: number;
    };
    topAccounts: {
        accountId: string;
        count: number;
        promptTokens: number;
        completionTokens: number;
        totalTokens: number;
    }[];
}

export enum ShipmentStatus {
    'PENDING' = 'PENDING',
    'IN_TRANSIT' = 'IN_TRANSIT',
    'DELIVERED' = 'DELIVERED',
    'CANCELLED' = 'CANCELLED',
}
export interface Shipment {
    id: string;
    accountId: string;
    trackingNumber: string;
    description: string;
    carrierType: string;
    status: ShipmentStatus;
    estimatedDelivery?: number;
    lastUpdateAt: number;
    createdAt: number;
}
